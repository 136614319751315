import React from "react";
import Icons from "../../../Constants/Icon";
import fiddlerchalet from '../../../Assets/Icons/texture-chalet.png';
import Bookfooter from '../../../component/footer/Bookfooter';
import Exclusive from '../../../component/CustomSlide/Exslider';
import CursorSlider from '../../../component/CustomSlide/chalersslider';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";

const slides = [

    {
        image: Icons.BabyGear,

    },
    {
        image: Icons.resort2,

    },
 
    {
        image: Icons.home13,

    },
    {
        image: Icons.resort4,

    },

];

const Sauveur = [{
    Rentals: "IMMERSE YOURSELF IN THE TRANQUILITY <br/> AND SPLENDOR OF FIDDLER LAKE RESORT",
    name: "THE EXPERIENCE",
    height: "100vh",
    bgimg: Icons.home13
  
}];
function Experience({ serviced, setitemsid }) {
    const service = serviced
    const element = document.getElementById(service);
    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
    const sectionimg = {
        backgroundImage: 'url(' + fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const { t } = useTranslation();
 
    const navigate = useNavigate();
    const handleRedirect = (item) => {
        navigate('/discover_chalets');
        setitemsid(item.id);
        setTimeout(() => {
            const div = document.getElementById(setitemsid);
            if (div) {
                div.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1000);
    };

    return (
        <div>
            <div className='family_fillte darkdata'>
                <BackgroundImage Sauveur={Sauveur} />
            </div>
       
            <section className="Directly md:mt-14 max-[767px]:mt-[25px] hidden">
                <Exclusive slides={slides} />
            </section>

            <section className='bg-skyYellow flex md:mb-24 max-[767px]:mb-10 items-center justify-center relative md:mt-[110px] max-[767px]:mt-12 md:h-[520px] max-[767px]:h-[166px] max-[374px]:h-full max-[374px]:py-5' >
                <div className='container px-8'>
                    <div className='grid grid-cols-2 md:gap-20 max-[767px]:gap-10 max-[374px]:grid-cols-1 max-[374px]:gap-12'>

                        <div >
                            <div className='grid grid-cols-2 md:gap-3 max-[767px]:gap-1 '>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[84px] p-2 md:-mt-12 max-[767px]:-mt-6 animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' style={{ overflowClipMargin: "unset" }} src={Icons.Activitiexpe} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[89px]  p-2 md:-mt-7  max-[767px]:-mt-3  animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' style={{ overflowClipMargin: "unset" }} src={Icons.Activitiexpe1} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[110px]  p-2 md:-mt-6 max-[767px]:-mt-4  animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' style={{ overflowClipMargin: "unset" }} src={Icons.Activitiexpe2} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[93px]  p-2  animation_img ' >
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' style={{ overflowClipMargin: "unset" }} src={Icons.Activitiexpe3} />

                                </div>
                            </div>
                        </div>
                        <div className='flex justify-left max-[767px]:justify-center items-center' >
                            <div >
                                <div className='flex items-center md:gap-8 max-[767px]:gap-4 md:ml-10 max-[767px]:ml-0 line-des1'>
                                    <div className='md:w-16 w-5  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                    <h3 className="AkagiPro-Black text-30 ">{t("AT THE RESORT")}</h3>
                                </div>
                                <h1 className=" text-64 Alfarn" style={{ lineHeight: "0.9" }}> <div dangerouslySetInnerHTML={{ __html: t("Amenities & Activities") }} /></h1>
                                <Link to='/resortactivities' className='text-white'> <button className=' bg-lightyellow text-20  mt-3 book_button p_button  AkagiPro-Black text-white uppercase' >{t("Learn more")}</button></Link>
                            </div>
                        </div>
                    </div>

                </div>

            </section >
            <section className='w-full bg-lightyellow md:py-16 max-[767px]:py-8  md:mt-24 max-[767px]:mt-12 text-white' style={sectionimg} >
                <div className='container  text-center' id={service}>
                    <div className="flex justify-center w-full  -mb-1">
                        <div className='flex items-center md:gap-8 max-[767px]:gap-2 line-des1'>
                            <div className='md:w-16 max-[767px]:w-5  bg-white  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                            <h3 className="AkagiPro-Black text-30 text-white"  >{t("CONCIERGE")}</h3>
                        </div>
                    </div>
                    <h1 className="  text-64 Alfarn " style={{ lineHeight: "60px" }}>{t("Services")}</h1>
                    <p className="text-center  text-20 AkagiPro-Black mb-0 mt-3">{t("Comprehensive services to enhance your stay:")}</p>
                    <div className="flex justify-center mt-9">
                        <div className="flex md:gap-7 max-[767px]:gap-5  grid-cols-3 md:mt-2  text-left   text-20 AkagiPro-Black md:w-[662px] lg:w-[900px]">
                            <div style={{ width: "50%" }}>
                                <ul className="grid gap-3 max-[767px]:pl-5" style={{ listStyle: "revert" }}>
                                    <li>{t("24/7 customer service")}</li>
                                    <li>{t("Restaurant and activity reservations")}</li>
                                    <li>{t("On-site convenience store and gift shop")}</li>
                                    <li>{t("Wedding and other event planning")}</li>
                                    <li>{t("Corporate event planning")}</li>
                                </ul>
                            </div>
                            <div className="bg-white h-full relative before:absolute before:bg-white 
                            before:w-1
                            before:rounded-full
                            before:h-1
                            before:-ml-[2px]
                            before:absolute
                            before:top-0
                            after:w-1 
                            after:rounded-full
                            after:h-1
                            after:-ml-[2px]
                            after:absolute
                            after:bottom-0
                            after:bg-white
                            
                            " style={{ width: "1px" }}>

                            </div>
                            <div style={{ width: "50%" }}>
                                <ul className="grid gap-3 max-[767px]:pl-4" style={{ listStyle: "revert" }}>
                                    <li >{t("Grocery delivery service")}</li>
                                    <li>{t("Catering service")}</li>
                                    <li>{t("Private chef")}</li>
                                    <li>{t("Car and limousine rental")}</li>

                                </ul>
                            </div>

                        </div>

                    </div>


                </div>
                <div className="flex items-center justify-center mt-4">
                    <Link to='/resortservices' className='text-white'> <button className=' bg-lightyellow text-20  mt-3 book_button p_button uppercase AkagiPro-Black  Services_button' >{t("Learn more")}</button></Link>
                </div>
            </section>
            <section className='bg-skyYellow flex items-center justify-center relative md:mt-32 max-[767px]:mt-12 md:h-[520px] max-[767px]:h-[166px] max-[374px]:h-full max-[374px]:py-5 mb-10 md:mb-24' >
                <div className='container px-8'>
                    <div className='grid grid-cols-2 max-[374px]:grid-cols-1 max-[374px]:gap-12'>
                        <div className='flex justify-left max-[767px]:justify-center items-center' >
                            <div >
                                <div className='flex items-center md:gap-8 max-[767px]:gap-4 md:ml-10 max-[767px]:ml-0 line-des1'>
                                    <div className='md:w-16 w-5  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                                    <h3 className="AkagiPro-Black text-30 ">{t("IN THE REGION")}</h3>
                                </div>
                                <h1 className=" text-64 Alfarn" style={{ lineHeight: "0.9" }}> <div dangerouslySetInnerHTML={{ __html: t("Activities &  <br />Restaurants") }} /></h1>
                                <Link to='/restaurants' className='text-white'> <button className=' bg-lightyellow text-20  mt-3 book_button p_button  AkagiPro-Black text-white uppercase' >{t("Learn more")}</button></Link>
                            </div>
                        </div>
                        <div >
                            <div className='grid grid-cols-2 md:gap-3 max-[767px]:gap-1 '>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[84px] p-2 md:-mt-12 max-[767px]:-mt-6 animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Region3} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[89px]  p-2 md:-mt-7  max-[767px]:-mt-3  animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Region2} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[110px]  p-2 md:-mt-6 max-[767px]:-mt-4  animation_img'>
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Region1} />

                                </div>
                                <div className='bg-black relative md:h-72 max-[767px]:h-[93px]  p-2  animation_img ' >
                                    <img className='absolute left-0 top-0 w-full h-full object-cover img_animation' src={Icons.Region} />

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </section >

            <section className="text-center container  px-8 md:mt-8 max-[767px]:mt-1">
              
                <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">{t("Discover our chalets")}</h1>
                <p className="text-20 AkagiPro-book md:mt-4">{t("Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ.")}</p>
                <p className="text-20 AkagiPro-book md:mt-4" dangerouslySetInnerHTML={{ __html: t("Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool.") }} />
                <p className="text-20 AkagiPro-book md:mt-4">{t("Read each model’s description for specific features and detailed information.")}</p>

            </section>
            <section className='relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ' >
                <div  >
                    <CursorSlider handleRedirect={handleRedirect} />
                </div>
            </section>

            <section className='md:mt-16'>
                <Bookfooter />

            </section>
        </div >
    )
}
export default Experience;
