import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./App/Screens/MainScreen/Home/Chalets";
import Header from "./App/Screens/MainScreen/Header/Headers";
import "./index.css";
import "./App.css";
import Experience from "./App/Screens/MainScreen/Experience/Experience";
import Groups from "./App/Screens/MainScreen/groups/groups";
import Contactus from "./App/Screens/MainScreen/Contactus/Contactus";
import Loader from "./App/component/Loader/Loader";
import Footer from "./App/component/footer/footer";
import Restaurants from "./App/Screens/MainScreen/Activities & Restaurants/Restaurants";
import Localactivities from "./App/Screens/MainScreen/Activities & Restaurants/resortactivities";
import Family from "./App/Screens/MainScreen/Home/family&friends/family&friends";
import Weddings from "./App/Screens/MainScreen/Home/Weddings/Weddings";
import Resort from "./App/Screens/MainScreen/Fiddler_Like_Resort/corporate";
import Descover from "./App/Screens/MainScreen/Discover/Discover_Chalets";
import { useTranslation } from "react-i18next";
import i18n from "./i18n";
import ScrollToTop from "./App/Screens/MainScreen/ScrollToTop/ScrollToTop";
import Resortservices from "./App/Screens/MainScreen/Resortservices/resortservices";
import Fiddler from "./App/Screens/MainScreen/Contactus/fiddlerpdf";
import Faq from "./App/Screens/MainScreen/faq/faq";
import Priacy from "./App/Screens/MainScreen/PrivacyPolicy/privacypolicy";
import Termsofservice from "./App/Screens/MainScreen/PrivacyPolicy/termsofservice";
import { initGA, trackPageLoadTime } from "./App/analytics";
import TermsAndConditions from "./App/Screens/MainScreen/TermsAndConditions/TermsAndConditions";
function App() {
  const [isLoading, setLoading] = useState(false);
  const [serviced, setServiced] = useState("");
  const [itemsid, setitemsid] = useState("");
  const [c_us, setc_us] = useState("");
  const defaultLanguage = "English";
  const { t } = useTranslation();
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      return storedLanguage;
    } else {
      localStorage.setItem("selectedLanguage", defaultLanguage);
      return defaultLanguage;
    }
  });

  useEffect(() => {
    if (location.pathname == "/fr" || location.pathname == "/fr/") {
      localStorage.setItem("selectedLanguage", "fr");
      i18n.changeLanguage("fr");
    }else if(location.pathname == "/en" || location.pathname == "/en/"){
      localStorage.setItem("selectedLanguage", "en");
      i18n.changeLanguage("en");
    } 
    else {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    initGA();
    trackPageLoadTime();
  }, []);

  const showHeaderFooter =
    location.pathname != "/tnc" && location.pathname !== "/tnc/";

  return (
    <div>
      <div>
        <Loader isLoading={isLoading} />

        <div>
          {showHeaderFooter && (
            <Header setLoading={setLoading} setServiced={setServiced} />
          )}
          <ScrollToTop>
            <PageTracker />
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    setLoading={setLoading}
                    setitemsid={setitemsid}
                    setc_us={setc_us}
                  />
                }
              />
              <Route
                path="/Experience"
                element={
                  <Experience serviced={serviced} setitemsid={setitemsid} />
                }
              />
              <Route
                path="/groups"
                element={<Groups setLoading={setLoading} />}
              />
              <Route
                path="/Contactus"
                element={<Contactus setLoading={setLoading} c_us={c_us} />}
              />
              <Route
                path="/restaurants"
                element={<Restaurants setitemsid={setitemsid} />}
              />
              <Route
                path="/family&friends"
                element={<Family setLoading={setLoading} />}
              />
              <Route
                path="/Weddings"
                element={<Weddings setLoading={setLoading} />}
              />
              <Route
                path="/corporate"
                element={<Resort setLoading={setLoading} />}
              />
              <Route
                path="/Discover_Chalets"
                element={<Descover itemsid={itemsid} setLoading={setLoading} />}
              />
              <Route
                path="/resortservices"
                element={<Resortservices setLoading={setLoading} />}
              />
              <Route
                path="/resortactivities"
                element={<Localactivities setitemsid={setitemsid} />}
              />
              <Route path="/faq" element={<Faq setLoading={setLoading} />} />
              <Route
                path="/fiddlerpdf"
                element={<Fiddler setLoading={setLoading} />}
              />
              <Route
                path="/privacypolicy"
                element={<Priacy setLoading={setLoading} />}
              />
              <Route
                path="/termsofservice"
                element={<Termsofservice setLoading={setLoading} />}
              />
              <Route
                path="/tnc"
                element={<TermsAndConditions setLoading={setLoading} />}
              />
              <Route path="/fr" element={<Navigate to="/" />} />
              <Route
                path="/"
                element={
                  <Home
                    setLoading={setLoading}
                    setitemsid={setitemsid}
                    setc_us={setc_us}
                  />
                }
              />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </ScrollToTop>
          {showHeaderFooter && <Footer setLoading={setLoading} />}
        </div>
      </div>
    </div>
  );
}
const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      const pageTitle = getPageTitle(location.pathname);
      window.gtag("config", "G-TDYD3F7NJF", {
        page_path: location.pathname,
        page_title: pageTitle,
      });
    }
  }, [location]);

  return null;
};

const getPageTitle = (pathname) => {
  switch (pathname) {
    case "/":
      return "Home Page";
    case "/Experience":
      return "Experience";
    case "/groups":
      return "groups";
    case "/Contactus":
      return "Contactus";
    case "/family&friends":
      return "family&friends";
    case "/Weddings":
      return "Weddings";
    case "/corporate":
      return "corporate";
    case "/Discover_Chalets":
      return "Discover_Chalets";
    case "/Discover":
      return "Discover";
    case "/resortservices":
      return "resortservices";
    case "/resortactivities":
      return "resortactivities";
    case "/faq":
      return "faq";
    case "/fiddlerpdf":
      return "fiddlerpdf";
    case "/privacypolicy":
      return "privacypolicy";
    case "/termsofservice":
      return "termsofservice";
    default:
      return "Page";
  }
};
export default App;
