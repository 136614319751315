import React from "react";
import BackgroundImage from "../../../component/backgroundtext/resortsbg";
import Icons from "../../../Constants/Icon";
import { useTranslation } from 'react-i18next';
import Pals from "../../../Assets/Icons/pulas.png";
import Munes from "../../../Assets/Icons/munes.png";
import { useState } from "react";
import Bookfooter from "../../../component/footer/Bookfooter";


const Sauveur = [{
    name: "Faq",
    height: "100vh",
    bgimg: Icons.Faq
}];
const Depending = [{
  
    height: "100vh",
    bgimg: Icons.Family9,
    Rentals: "Faq",



}]

const faqData = [

    {
        question:"1. What is included with the chalet rental?" ,
        answer:"- Bedding: sheets, duvet and pillows;<br/>- Bath towels, hand towels, washcloths and spa/pool towels;<br/>- Dishcloths and dish soap;<br/>- Bathroom tissue, paper towels and hand soap;<br/>- Fully equipped kitchen (food not provided);<br/>- Coffee maker and Keurig single serve coffee maker;<br/>- High-speed Internet;<br/>- Smart TV; <br/>- Propane barbecue with grilling utensils; <br/>- Patio set and/or picnic table."

    },
    {
        question:"2. Do we have to bring our own bedding and towels?" ,
        answer:"Towels and bedding are provided for each person for the duration of their stay.<br/><br/>In addition, pool towels are available in the chalets for use in the hot tub, pools, and lake."

    },
    {
        question:"3. Do the chalets have washers and dryers?" ,
        answer:"Yes, all the chalets are equipped with a washer and dryer. Laundry detergent is included for the<br/>first load of laundry."

    },
    {
        question:"4. Is a barbecue available at the chalet?" ,
        answer:"Yes. In addition, propane and grilling utensils are provided for the duration of your stay."

    },
    {
        question:"5. Are the chalets non-smoking?" ,
        answer:"Yes, all our chalets are non-smoking, without exception. It is strictly forbidden to smoke any<br/>substance inside the chalets. Any violation will result in a fine of up to five hundred dollars<br/>($500.00) per occurrence. Any damage caused will be at the renter’s expense. Fiddler Lake<br/>Resort  reserves the right to terminate the rental contract in the event of non-compliance."

    },
    {
        question:"6. Are pets allowed in the chalets?" ,
        answer:"No, pets are not allowed in the chalets."

    },  {
        question:"7. What time can we check in?" ,
        answer:"Check-in time is as of 4:00 p.m. and check-out time is before 11:00 a.m. on the day of check-<br/>out."

    },  {
        question:"8. What is the minimum age to rent a chalet?" ,
        answer:"You must be at least 25 years old to rent a chalet. At least 1 adult aged 25 and over must be<br/>present at all times during your stay."

    },  {
        question:"9. Is access to the Recreation Centre included with our chalet rental?" ,
        answer:"Yes, when you reserve your stay directly with Fiddler Lake Resort Rentals, you automatically<br/>receive unlimited access to the Recreation Center, including access to our watercraft fleet and<br/>recreational equipment. "

    },
    {
        question:"10. Is there a convenience store or grocery store nearby?" ,
        answer:"Yes, there is a convenience store and gift shop at the Recreation Centre. You can purchase<br>wood, milk, butter, drinks, snacks and much more (in limited quantities)!<br/><br/>In Morin-Heights, just a 10-minute drive from Fiddler Lake Resort, you will find an IGA grocery<br/>store, a gas station and a SAQ liquor store."

    }
];

function About() {
    const [expandedItems, setExpandedItems] = useState({});
    const [activeItem, setActiveItem] = useState(null);

    const toggleItem = (index) => {
        setExpandedItems((prev) => ({
            [index]: !prev[index]
        }));
        setActiveItem(activeItem === index ? null : index);
    };

    const { t } = useTranslation();
    return (
        <div className="About_d">
            <div className='family_fillte'>
                <BackgroundImage Depending={Depending} />
            </div>
            <div className="bg-skyYellow my-4 md:py-20 max-[767px]:py-10">
                <div className="container">
                    <div className="max-w-[1150px] mx-auto">
                        {faqData.map((item, index) => (
                            <div key={index} className="faq_fg">
                                <button
                                    onClick={() => toggleItem(index)}
                                    className={activeItem === index ? 'active' : 'faq_header'}
                                >
                                    <div className="text-30 Akagi-Pro-blacks text-left">{t(item.question)}</div>
                                    <div className="pule icon_p"><img src={Munes} /></div>
                                    <div className="munes icon_p"><img src={Pals} /></div>
                                </button>
                                <div className="md:mt-7 max-[767px]:mt-2 md:mb-11">
                                    {activeItem === index && (
                                        <p style={{ fontFamily: 'Akagi Pro, sans-serif' }} className="text-20  md:pl-8 max-[767px]:pl-4 md:mb-14" dangerouslySetInnerHTML={{ __html: t(item.answer) }} />
                                    )}
                                </div>
                                <div className="w-full h-[1px] bg-black md:mb-7 md:-mt-5  max-[767px]:my-3"></div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Bookfooter />
        </div>
    );
}

export default About;
