import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../Constants/Icon";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function SimpleSlider({  }) {


//   const Sauveur = [{
//     bgimg: Icons.website,
//     name: "FIDDLER LAKE RESORT",
//     button: "book now",
//     height: responsiveHeight,
//     className: "gooddes",
//     Rentals: "THE ULTIMATE CHALET GETAWAY"
// },

  const [shouldUseImage, setShouldUseImage] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    autoplay: false,
    autoplaySpeed: 12000,
  };
  const { t } = useTranslation();

  const videoRef = useRef(null);

  useEffect(() => {
    if (isSafari() && videoRef.current) {
      const player = videoRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); 
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  // console.log("SauveurSauveur",Sauveur);
  
  return (
    <div className="relative data_img overflow-hidden">
      <div className="absolute w-full h-full flex justify-center items-center z-10 top-0">
          <div className="text-center z-10 text-white">
            <h3 class=" Alfarn text-48 ">{t('FIDDLER LAKE RESORT')}</h3>
          </div>
      </div>
        {/* {Sauveur.map((item, index) => { */}
          {/* return ( */}
            <div className="relative" >
              {  shouldUseImage ? (
                  <img src={Icons.website} alt="Muted Video" />
                ) : (
                  <div
                  style={{  width: '100%' }}
                    ref={videoRef}
                    dangerouslySetInnerHTML={{
                      __html: `
        <video
          loop
          className="data_img h-full w-full object-cover Depending_img slider_img"
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${Icons.website}" type="video/mp4" />
        </video>`,
                    }}
                  />
                )
            }
            <div className="black_s"></div>
            </div>
          {/* ); */}
        {/* })} */}
    </div>
  );
}
