import { Link } from "react-router-dom";
import Icons from "../../Constants/Icon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BookNow from "../Button/BookNow";
import axios from "axios";
import { BaseNewsLetterUrl } from "../../Core/Until";
function Footer({ setLoading }) {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [newsLetterMessage, setNewsletterMessage] = useState("");
 
  const handleLinkClick = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email.trim() !== "") {
      setEmail("");
    } else {
      setIsValidEmail(false);
    }
    try {
      setLoading(true);
      const response = await axios.post(BaseNewsLetterUrl, {
        email: email,
        language:
          localStorage.getItem("selectedLanguage") == "fr" ? "fr" : "en",
      });
      setLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 5000);
      setEmail("");
    } catch (error) {
      setNewsletterMessage(error?.response?.data?.message);
      setLoading(false);
      setIsError(true);
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  };

  
  const { t } = useTranslation();
  return (
    <div
      className="bg-treecolor py-12  max-[768px]:p-5"
      style={{ minHeight: "400px" }}
    >
      <div className="container">
        <div className="flex gap-36 max-[1000px]:gap-8 max-[768px]:block">
          <div className="max-[768px]:flex max-[768px]:justify-center max-[768px]:mb-7 lg:w-[426px]">
            <Link to="./">
              {/* <img
                  src={Icons.Group}
                  style={{height:90,width:'100%',marginLeft:40,objectFit:"contain"}}
                  class="mr-3  max-[767px]:mr-0 w-72 max-[767px]:w-24"
                  alt="Flowbite Logo"
                />
                 */}
              <img
                src={Icons.Group}
                style={{ height: 150, objectFit: "contain" }}
                className=" max-[767px]:w-24 lg:w-[426px] md:w-[426px]"
              />
            </Link>
          </div>
          <div className="w-full ">
            <h3
              className="Alfarn text-white text-30 mb-2  max-[768px]:mb-1"
              style={{ lineHeight: "47px" }}
            >
              {t("Newsletter Sign Up")}
            </h3>
            <div className="pr-24 max-[1000px]:pr-0">
              <form onSubmit={handleSubmit} className="flex  max-[768px]:gap-3">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="text-20 w-full border-lightyellow placeholder:text-white bg-transparent py-2 md:pb-0 focus:outline-none hover:border-none  text-white mr-6"
                  style={{ borderBottom: "1px solid white" }}
                  placeholder={t("Enter your email address")}
                />

                <button
                  type="submit"
                  className="book_button  md:w-44 max-[767px]:w-36  AkagiPro-Black uppercase"
                >
                  {t("Sign up")}
                </button>
              </form>
              {!isValidEmail && (
                <p className="text-red-500">
                  {t("Please enter a valid email address")}
                </p>
              )}
              {isError && (
                <p style={{ color: "red" }}>
                  {/* {t("Already subscribed. Try a different email.")} */}
                  {newsLetterMessage}
                </p>
              )}
              {isSuccess && (
                <p className="text-green-500">
                  {t(
                    "Congratulations! You're now subscribed to our newsletter. Stay tuned for updates and exclusive offers!"
                  )}
                </p>
              )}
            </div>
            <div
              className="max-[1000px]:gap-0  grid grid-cols-3 mt-28 max-[1000px]:mt-9 max-[1000px]:flex flex-wrap -ml-5  justify-between max-[768px]:-ml-0 gap_0  "
              style={{ width: "100%" }}
            >
              <div className="text-lightyellow capitalize AkagiPro-Black text-20 md:grid   max-[1000px]:w-1/2  ">
                <div className="flex justify-betwee gap-2 md:mb-4 max-[767px]:mb-3  cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow cursor-pointer"
                    to="/discover_chalets"
                  >
                    {t("Our Chalets")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/resortactivities"
                  >
                    {t("Resort Activities")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/resortservices"
                  >
                    {t("Resort Services")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/restaurants"
                  >
                    {t("Local Activities & Dining")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/family&friends"
                  >
                    {t("Family & Friends")}
                  </Link>
                </div>
              </div>
              <div className="text-lightyellow capitalize AkagiPro-Black text-20  max-[768px]:w-1/2  md:justify-center max-[500px]:pl-3 ">
                {/* <div className='flex justify-betwee gap-2 mb-2 cursor-pointer items-baseline'><img src={Icons.Tira} className=' max-[768px]:w-7' /><Link onClick={handleLinkClick} className='text-lightyellow  cursor-pointer' to="/Promotions">promotions</Link></div> */}
                <div className="flex justify-betwee gap-2 md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/corporate"
                  >
                    {t("corporate groups")}
                  </Link>
                </div>
                {/* <div className='flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2'><img src={Icons.Tira} className=' max-[768px]:w-7' /><Link onClick={handleLinkClick} className='text-lightyellow  cursor-pointer' to="/about">{t("about us")}</Link></div> */}
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer capitalize"
                    to="/weddings"
                  >
                    {t("Weddings")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/contactus"
                  >
                    {t("Contact us")}
                  </Link>
                </div>
                <div className="flex justify-betwee gap-2  md:mb-4 max-[767px]:mb-3 cursor-pointer items-baseline max-[1396px]:min-h-12 max-[1024px]:min-h-2">
                  <img src={Icons.Tira} className=" max-[768px]:w-7" />
                  <Link
                    onClick={handleLinkClick}
                    className="text-lightyellow  cursor-pointer"
                    to="/faq"
                  >
                    {t("FAQ")}
                  </Link>
                </div>
              </div>
              <div className="max-[1000px]:flex max-[1000px]:w-full flex-wrap  justify-between max-[1000px]:mt-12  max-[335px]:justify-center">
                <h2 className="text-30  capitalize Alfarn text-white -mt-3 text-center items-center max-[1000px]:flex  max-[1000px]:mt-0">
                  {t("Our Awards")}{" "}
                </h2>
                <div className="flex justify-center gap-2 items-baseline flex-wrap mt-6 max-[1000px]:mt-0">
                  <img src={Icons.traveler2} className=" max-[768px]:w-12" />

                  <img src={Icons.traveler1} className=" max-[768px]:w-12" />
                  <img src={Icons.traveler} className=" max-[768px]:w-14" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full text-center">
          <p className="text-sm text-21  mt-20 max-[768px]:mt-16  mb-4 AkagiPro-Black text-white ">
            {" "}
            {t("This site is protected by reCAPTCHA and the Google")}{" "}
            {BookNow.map((BookNow) => (
              <Link className="text-white" to={"/privacypolicy"}>
                <span className="cursor-pointer underline mx-1">
                  {t("Privacy Policy")}
                </span>
              </Link>
            ))}
            {t("and")}{" "}
            {BookNow.map((BookNow) => (
              <Link to={"/termsofservice"} className="text-white">
                <span className="cursor-pointer underline mx-1">
                  {" "}
                  {t("Terms of Service")}
                </span>
              </Link>
            ))}
            {t("apply.")}
          </p>
          <h2 className="text-20 text-21  capitalize AkagiPro-Black text-lightyellow -mt-3 text-center">
            {t("© 2024 Fiddler Lake Resort")}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Footer;
