
const BookNow = [
    {
        Link: 'https://reservations.fiddlerlakeresort.com/',
        name: 'Book Now',
        lanermor: "/discover_chalets",
        thingsnearby: 'https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians',
        discover: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        viewmore: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        askforinformation: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        BROWSEOURCHALETS: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        forcompanies: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        forlovebirds: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        yourceremony: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        Startplanning: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians",
        Plan: "https://www.fiddlerlakeresort.com/en/cottage-for-rent-laurentians"

    },


];

export default BookNow;
